// Imported in non-production environments only, helps with debugging
import 'zone.js/dist/zone-error';

import type { EnvironmentInterface } from '../interfaces/src/interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAF_76vj2kU97q3tLQJP3jqrCDX3axX9Vo",
    authDomain: "blush-qa.firebaseapp.com",
    projectId: "blush-qa",
    storageBucket: "blush-qa.appspot.com",
    messagingSenderId: "386521661036",
    appId: "1:386521661036:web:e870bc7e139fc3de0d2249",
    measurementId: "G-K0E6VK7CPK",
    databaseURL: "https://blush-qa-default-rtdb.firebaseio.com"

  },
  apiUrl: 'https://us-central1-blush-qa.cloudfunctions.net',
  mapsApiKey: 'AIzaSyBs4kc0MSe-kxU8w2lrDfhauw2Xfq_yqKc',
  release: '1.3.0',
  envName: 'QA',
  searchAPIkey: 'd673dc9651f37064602675b471404a43',
  makioUrl: 'https://makio-dev.leafvip.app',
  // makioUrl: 'http://localhost:3000',
  baseBrandConnectUrl: 'https://connect.qa.blushco.io',
  baseOfficeConnectUrl: 'https://office.qa.blushco.io',
  baseTeamManagerUrl: 'https://team-manager.qa.blushco.io',
  leafVipBrandId: 'ZQ7AtssmT3NzNnAfGfbB',
};
